<template>
  <login-wrapper class="login">
    <dog-form
      ref="dogForm"
      :form-data="formData"
      :form-options="formOptions"
      label-width="70px"
    ></dog-form>
    <div class="login__operate flex_row">
      <div class="flex_1">
        <el-checkbox v-model="remember">记住密码</el-checkbox>
      </div>
      <el-link :underline="false" type="primary" @click="goForget"
        >忘记密码</el-link
      >
    </div>
    <div class="login__btn">
      <el-button type="primary" @click="login">登录</el-button>
      <el-button @click="goRegister">注册</el-button>
    </div>
  </login-wrapper>
</template>

<script>
  import userService from '@/api/service/user';
  import loginWrapper from './loginWrapper.vue';
  import captcha from '@/components/captcha.vue';
  import md5 from 'js-md5';

  export default {
    name: 'login',
    components: { loginWrapper },
    data() {
      return {
        formData: {},
        formOptions: [
          {
            type: 'input',
            formItem: {
              label: '用户名',
              prop: 'userName',
              rules: [this.$formRules.required('请输入用户名')]
            },
            listeners: {
              confirm: () => {
                this.login();
              }
            }
          },
          {
            type: 'input',
            formItem: {
              label: '密码',
              prop: 'password',
              rules: [this.$formRules.required('请输入密码')]
            },
            attrs: {
              type: 'password',
              showPassword: true
            },
            listeners: {
              confirm: () => {
                this.login();
              }
            }
          },
          {
            type: 'custom',
            formItem: {
              label: '验证码',
              prop: 'text',
              rules: [this.$formRules.required('请输入验证码')]
            },
            component: captcha,
            listeners: {
              confirm: () => {
                this.login();
              }
            }
          }
        ],
        remember: true
      };
    },
    mounted() {
      if (this.$store.getters.loginToken) {
        this.goHome();
      }
    },
    methods: {
      /**
       * 登录
       */
      login() {
        this.$refs.dogForm.validate().then(() => {
          userService
            .login({
              userName: this.formData.userName,
              password: md5(this.formData.password),
              text: this.formData.text
            })
            .then((res) => {
              this.$message.success('登录成功！');
              this.$store
                .dispatch('setToken', {
                  loginToken: 'Bearer ' + res.loginToken,
                  remember: this.remember
                })
                .then(() => {
                  this.goHome();
                });
            });
        });
      },
      /**
       * 回到首页
       */
      goHome() {
        const query = this.$route.query;
        const path = query && query.from ? query.from : '/';
        this.$router.push(path);
      },
      /**
       * 忘记密码
       */
      goForget() {
        this.$router.push({
          path: '/forget',
          query: this.$route.query
        });
      },
      /**
       * 注册
       */
      goRegister() {
        this.$router.push({
          path: '/register',
          query: this.$route.query
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  @include b(login) {
    @include e(operate) {
      margin-bottom: 25px;
    }
    @include e(btn) {
      .el-button {
        display: block;
        width: 100%;
        &:last-child {
          margin-top: 8px;
          margin-left: 0;
        }
      }
    }
  }
</style>
