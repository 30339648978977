<template>
  <div class="loginWrapper">
    <div class="loginWrapper__form">
      <slot></slot>
    </div>
    <right-footer class="loginWrapper__footer"></right-footer>
  </div>
</template>

<script>
  import rightFooter from '@/components/layout/rightFooter.vue';

  export default {
    name: 'loginWrapper',
    components: { rightFooter }
  };
</script>

<style lang="scss" scoped>
  @include b(loginWrapper) {
    position: relative;
    min-height: 100vh;
    padding: 50px 20px;
    background-image: url('../../assets/images/background.png');
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    @include e(form) {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      width: 360px;
      max-width: 100%;
      padding: 25px 30px;
      margin: 0 auto;
      margin-top: -220px;
      background-color: $color-white;
      border-radius: 4px;
      transform: translateX(-50%);
      box-sizing: border-box;
    }
    @include e(footer) {
      position: absolute;
      right: 0;
      bottom: 20px;
      left: 0;
      z-index: 1;
      padding: 20px 0 0;
      color: #eee;
      background-color: transparent;
    }
  }
</style>
