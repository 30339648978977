<template>
  <div class="flex_row checkCode">
    <dog-input
      v-model="codeValue"
      @input="onInput"
      @keydown.enter.native="confirm"
    ></dog-input>
    <div v-html="svgHtml" class="flex_row pointer" @click="getCaptcha"></div>
  </div>
</template>

<script>
  import utilService from '@/api/service/util';

  export default {
    name: 'captcha',
    props: {
      cur: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        codeValue: '',
        svgHtml: ''
      };
    },
    watch: {
      cur: {
        handler(newVal) {
          this.codeValue = newVal;
        },
        immediate: true
      }
    },
    mounted() {
      this.getCaptcha();
    },
    methods: {
      onInput(val) {
        this.$emit('input', val);
      },
      getCaptcha() {
        utilService.getCaptcha().then((res) => {
          this.svgHtml = res.data;
        });
      },
      confirm() {
        this.$emit('confirm');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .checkCode {
    align-items: center;
  }
</style>
